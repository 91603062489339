// import { useQuery } from 'react-query'
// import Api from '../api/Market'
import { create } from 'zustand'

// auth global state  using zustand
// TODO: add react-query to handle the api calls and server state
// TODO: move all the auth logic (cookies, localstorage, etc) to this file

export const useIsLoggedIn = create((set) => ({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn })
}))

export const useAuthPhone = create((set) => ({
  authPhone: '',
  setAuthPhone: (authPhone) => set({ authPhone })
}))
