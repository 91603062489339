import { GET, POST, POSTFORM, DELETE, POSTUNTRACK, GETUNTRACK } from '#api/Fetch'

import API from '#api/index'
import Cookies from 'js-cookie'

class AuthApi extends API {
    validatePhoneNumber(type, countryCode, phoneNumber, recaptchaToken) {
        return POST(`${this.url}/api/auth/validate-phone-number/doc`,
        {
            type: type,
            countryCode: countryCode,
            phoneNumber: phoneNumber,
            recaptchaToken: recaptchaToken
        })
    }
    register(userData){
        Cookies.get('userToken')
        return POST (`${this.url}/api/auth/register/doc`, userData, { Authorization: `Bearer ${Cookies.get('userToken')}` })
    }
    registerExistingUser(userData){
        return POST (`${this.url}/api/auth/registerExisting/doc`, userData)
    }
    phoneNumber(type, countryCode, phoneNumber, recaptchaToken) {
        return POST(`${this.url}/api/auth/phone-number/doc`,
        {
            type: type,
            countryCode: countryCode,
            phoneNumber: phoneNumber,
            recaptchaToken: recaptchaToken
        })
    }
    otpLogin(code, sessionId, recaptchaToken) {
        return POST(`${this.url}/api/auth/otp-login/doc`,
        {
            code: code,
            sessionId: sessionId,
            recaptchaToken: recaptchaToken
        })
    }
    login(email, password, recaptchaToken) {
        return POST(`${this.url}/api/auth/b2c-login/doc`,
        {
            email: email,
            password: password,
            recaptchaToken: recaptchaToken
        })
    }
    googleLogin(token_id){
        return POST(`${this.url}/api/auth/google-login/doc`,
        {
            googleAccessToken: token_id,
            referrer: 'oneTap'
        }) 
    }
    logout(id){
        Cookies.remove('userToken')
        localStorage.removeItem('userDetails')
        //redirect to homepage
        window.location.href = '/'
        // return POST(`${this.url}/api/auth/logout/doc`,
        // {
        //     id: id,
        // }) 
    }
    emailOtp(email, recaptchaToken){
        return POST(`${process.env.NEXT_PUBLIC_AUTH_API_URL}user-service/email-otp`,
        {
            email: email,
            recaptchaToken: recaptchaToken
        }) 
    }
    verifyToken(token, accessToken){
        //replace guest token with new token
        Cookies.set('userToken', accessToken)
        //return userDetails
        return GET(`${process.env.NEXT_PUBLIC_AUTH_API_URL}user-service/me`, {}, { Authorization: `Bearer ${token}` })
    }
    resetPassword(sessionId, code, password, token){
        return POST(`${process.env.NEXT_PUBLIC_AUTH_API_URL}user-service/forgot-password`,
        {
            sessionId: sessionId,
            code: code,
            password: password,
            recaptchaToken: token
        })
    }
    profile(token){
        return GET(`${process.env.NEXT_PUBLIC_AUTH_API_URL}user-service/profile`, {}, {Authorization: `Bearer ${token}`})
    }
    updateProfile(userDetails, token){
        return POST (`${this.url}/api/auth/update-profile/doc`, userDetails, {Authorization: `Bearer ${token}`})
    }
    code(payload, token){
        return POST (`${process.env.NEXT_PUBLIC_AUTH_API_URL}otp-service/code`, payload, {Authorization: `Bearer${token}`})
    }
    checkExistence(checkType, value){
        return GET(`${this.url}/api/auth/check-existence`, {checkType, value}, {})
    }
}

let Api = new AuthApi()
export default Api